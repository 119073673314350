import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'


const username = document.getElementById("username")
const password = document.getElementById("password")
const usernameError = document.getElementById("usernameError")
const passwordError = document.getElementById("passwordError")
const loginForm = document.getElementById("password-login-form")

loginForm.onsubmit = function(e) {
  e.preventDefault()
  usernameError.innerText = ''
  passwordError.innerText = ''
  console.log(username.value, isEmail(username.value))
  let isInvalid = false
  if (username.value !== 'Administrator' && !isEmail(username.value)) {
    usernameError.innerText = 'Invalid email address'
    isInvalid = true
  }
  console.log(password.value, !isEmpty(password.value))
  if (isEmpty(password.value)) {
    passwordError.innerText = 'Please enter a valid password'
    isInvalid = true
  }
  if (isInvalid) return

  console.log('is valid')
  const formData = new FormData()
  formData.append('cmd', 'login')
  formData.append('usr', username.value)
  formData.append('pwd', password.value)
  fetch("/login", {
    method: "POST",
    body: formData
  }).then((resp) => {
    if (resp.status === 200) {
      const searchParams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      })
      const redirectTo = decodeURIComponent(searchParams['redirect-to'])
      window.location.href = redirectTo ?? '/app'
    } else {
      passwordError.innerText = 'Invalid username or password'  
    }
  }).catch((e) => {
    console.error(e)
    passwordError.innerText = 'Invalid username or password'
  })
}

